import angular, { IPromise, IQService } from 'angular';

/**
 * BusyLight is a prototype of a helper class I designed that returns isBusy for as long as
 * a promise operation is currently being executed.
 *
 * Example:
 *      var busyLight = new BusyLight
 *      busyLight.addPromise(someHttpRequest)
 *      busyLight.addPromise(someTimeoutRequest)
 *      busyLight.isBusy    // returns true until both promises resolve
 */

function busyLightFactory($q: IQService) {
  class BusyLight {
    private promiseCurr: IPromise<any> = $q.resolve();
    private promiseTotalCount = 0;
    private promiseCurrCount = 0;

    get isBusy() {
      return this.promiseTotalCount != this.promiseCurrCount;
    }

    public addPromise(args: IPromise<any> | IPromise<any>[]) {
      if (Array.isArray(args)) {
        const prs: IPromise<any>[] = args;

        this.promiseTotalCount += prs.length;
        this.promiseCurr = this.promiseCurr.then(() =>
          $q.all(prs).finally(() => (this.promiseCurrCount += prs.length))
        );
      } else {
        const pr: IPromise<any> = args;

        this.promiseTotalCount++;
        this.promiseCurr = this.promiseCurr.then(() =>
          pr.finally(() => this.promiseCurrCount++)
        );
      }
    }
  }

  return BusyLight;
}

angular
  .module('flowingly.services')
  .factory('BusyLight', ['$q', busyLightFactory]);

export type BusyLightFactoryType = ReturnType<typeof busyLightFactory>;
export type BusyLightType = InstanceType<BusyLightFactoryType>;
