/**
 * @ngdoc service
 * @name userApiService
 * @module flowingly.services
 *
 * @description A service responsible solely for fetching users data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 *
 */

'use strict';
import IUserSummary from '@Shared.Angular/@types/core/contracts/queryModel/user/userSummary';
import IUserBasicInfo from '@Shared.Angular/@types/core/contracts/queryModel/user/UserBasicInfo';
import angular from 'angular';
/// i.e. we return response.data.DataModel so the consumer can work with the data directly

angular.module('flowingly.services').factory('userApiService', userApiService);

userApiService.$inject = [
  '$http',
  'APP_CONFIG',
  'sessionService',
  'lodashService',
  'tokenService'
];

function userApiService(
  $http: angular.IHttpService,
  APP_CONFIG,
  sessionService,
  lodashService,
  tokenService
) {
  const service = {
    users: [],
    addUser: addUser,
    addUsers: addUsers,
    deleteUser: deleteUser,
    editUser: editUser,
    getUsers: getUsers,
    getUser: getUser,
    getUsersWithOptions: getUsersWithOptions,
    getUsersOfRole: getUsersOfRole,
    getUsersWithPermission: getUsersWithPermission,
    getUserAvatars: getUserAvatars,
    getUserInvolvementInFlow: getUserInvolvementInFlow,
    registerUserMobileNotification: registerUserMobileNotification,
    unregisterUserMobileNotification: unregisterUserMobileNotification,
    userLoginAudit: userLoginAudit,
    userLoginErrorAudit: userLoginErrorAudit,
    searchUsers: searchUsers,
    searchUsersCustomDB: searchUsersCustomDB,
    getLoginState: getLoginState,
    updateLoginState: updateLoginState,
    getUserProfileUsingId: getUserProfileUsingId,
    getUsersBasicInfo: getUsersBasicInfo,
    getUserBasicInfoByFieldName: getUserBasicInfoByFieldName
  };

  return service;

  //////////// Public API Methods

  function addUser(user) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + 'users/', user)
      .then(function (response) {
        service.users.push(response.data.dataModel); // we should stop doing this or atleast imrpove our caching mechanism
        return response;
      });
  }

  function addUsers(users, noSpinner) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + 'users/addUsers', users, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response;
      });
  }

  function getUserInvolvementInFlow(userId, flowId) {
    return $http
      .get(`${APP_CONFIG.apiBaseUrl}users/${userId}/flow/${flowId}`)
      .then((response) => {
        return response.data;
      });
  }

  function deleteUser(
    userId,
    replaceActorId,
    replaceManagerId,
    replaceCustomDatabaseActorId
  ) {
    //send complex object in delete request
    return $http({
      url: APP_CONFIG.apiBaseUrl + 'users/',
      method: 'DELETE',
      data: {
        deletedUserId: userId,
        replaceActorId: replaceActorId,
        replaceManagerId: replaceManagerId,
        replaceCustomDatabaseActorId: replaceCustomDatabaseActorId
      },
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function editUser(user) {
    return $http
      .put(APP_CONFIG.apiBaseUrl + 'users/', user)
      .then(function (response) {
        service.users.push(response.data.dataModel);
        return response;
      });
  }

  function getUsers(noSpinner) {
    return $http
      .get<IResponseData<IUserSummary[]>>(
        APP_CONFIG.apiBaseUrl +
          'users?businessId=' +
          tokenService.getTenant().id,
        { noSpinner: noSpinner || false }
      )
      .then(function (response) {
        angular.copy(response.data.dataModel, service.users);
        return response.data.dataModel;
      });
  }

  function getUser(userId: string) {
    // @TODO replace this with a better api endpoint
    // as of 18/02/2019 there is no get single Id endpoint
    const filterParams = {
      filter: {
        filters: [{ field: 'id', value: userId }]
      }
    };
    return getUsersWithOptions(filterParams).then(({ total, users }) => {
      return users.pop();
    });
  }

  /**
   * Given a {clientId} search for {limit} amount of users
   * and optionally filter the first and last name by a term.
   *
   * @param {guid} clientId  business id
   * @param {string} term string to filter
   * @param {number} limit amount to limit by
   * @param {boolean} noSpinner
   */
  function searchUsers({
    clientId = null,
    term = null,
    limit = 100,
    noSpinner = true
  } = {}) {
    const url = `${APP_CONFIG.apiBaseUrl}users/search`;

    const params = {
      businessId: clientId || tokenService.getTenant().id,
      maxNumberOfUsersToShow: limit,
      searchTerm: undefined
    };

    if (term) {
      params.searchTerm = term;
    }

    const options = { noSpinner, params, headers: undefined };
    const currentUser = sessionService.getUser();
    const token = tokenService.getToken();
    if (!currentUser && token) {
      options.headers = { Authorization: 'Bearer ' + token };
    }

    return $http.get(url, options).then(function (response) {
      return response.data.userDetailForModals;
    });
  }

  function searchUsersCustomDB({
    clientId = null,
    limit = 200,
    noSpinner = true
  } = {}) {
    const url = `${APP_CONFIG.apiBaseUrl}users/customdb`;

    const params = {
      clientId: clientId || tokenService.getTenant().id,
      maxNumberOfUsersToShow: limit
    };

    const options = { noSpinner, params, headers: undefined };

    const currentUser = sessionService.getUser();
    const token = tokenService.getToken();
    if (!currentUser && token) {
      options.headers = { Authorization: 'Bearer ' + token };
    }

    return $http.get(url, options).then(function (response) {
      return response.data.userDetailForModals;
    });
  }

  function getUsersWithOptions(options) {
    const defaultOpts = {
      take: 100,
      skip: 0,
      page: 1,
      pageSize: 100,
      group: []
    };
    options = lodashService.defaults(options, defaultOpts);
    return $http
      .post(APP_CONFIG.apiBaseUrl + 'users/getUsersWithOptions', options, {
        noSpinner: true
      })
      .then(function (response) {
        angular.copy(response.data.users, service.users);
        return response.data;
      });
  }

  function getUsersOfRole(roleName, noSpinner) {
    return $http
      .get(APP_CONFIG.apiBaseUrl + 'users/usersOfRole?roleName=' + roleName, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function getUsersWithPermission(permissionCode, noSpinner = false) {
    return $http
      .get(APP_CONFIG.apiBaseUrl + 'users?permissionCode=' + permissionCode, {
        noSpinner: noSpinner || false
      })
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function getUserAvatars() {
    return $http
      .get(APP_CONFIG.apiBaseUrl + 'users/useravatars/')
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function registerUserMobileNotification(userId, isIos) {
    console.log('registerUserMobileNotification for userId: ' + userId);

    if (
      (window as any).flowinglyCordova.pushNotificationHandle != null &&
      typeof (window as any).flowinglyCordova.pushNotificationHandle !==
        'undefined'
    ) {
      return $http
        .post(
          APP_CONFIG.apiBaseUrl + 'mobile/registerForNotification',
          {
            handle: (window as any).flowinglyCordova.pushNotificationHandle,
            userId: userId,
            isIos: isIos
          },
          { noSpinner: true }
        )
        .then(function (response) {
          return response.data;
        });
    } else console.log('pushNotificationHandle is null.');
  }

  function unregisterUserMobileNotification() {
    return $http
      .post(
        APP_CONFIG.apiBaseUrl +
          'mobile/unregisterForNotification?handle=' +
          (window as any).flowinglyCordova.pushNotificationHandle,
        { noSpinner: true }
      )
      .then(function (response) {
        return response.data;
      });
  }

  function userLoginAudit(loginAudit) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + 'account/loginAudit', loginAudit, {
        noSpinner: false
      })
      .then(function (response) {
        return response;
      });
  }

  function userLoginErrorAudit(loginAudit) {
    return $http.post(
      APP_CONFIG.apiBaseUrl + 'account/loginFailAudit',
      loginAudit,
      { noSpinner: false }
    );
  }

  function getLoginState() {
    return $http
      .get(APP_CONFIG.apiBaseUrl + `account/loggedIn`)
      .then(function (response) {
        return response.data.dataModel;
      });
  }

  function updateLoginState(loggedIn: boolean) {
    return $http.put(APP_CONFIG.apiBaseUrl + `account/loggedIn/${loggedIn}`);
  }

  function getUserProfileUsingId(userId: string) {
    return $http
      .get<IUserSummary>(APP_CONFIG.apiBaseUrl + `users/${userId}/profile`, {
        noSpinner: true
      })
      .then(function (response) {
        return response.data;
      });
  }

  function getUsersBasicInfo(options) {
    const defaultOpts = {
      take: 100,
      skip: 0,
      page: 1,
      pageSize: 100,
      group: []
    };
    options = lodashService.defaults(options, defaultOpts);
    return $http
      .post(APP_CONFIG.apiBaseUrl + 'users/basic', options, {
        noSpinner: true
      })
      .then(function (response) {
        return response.data as { users: IUserBasicInfo[]; total: number };
      });
  }

  function getUserBasicInfoByFieldName(userId: string, fieldName = 'id') {
    const filterParams = {
      filter: {
        filters: [{ field: fieldName, value: userId }]
      }
    };
    return getUsersBasicInfo(filterParams).then(({ total, users }) => {
      return users.pop();
    });
  }
}

// get type of userApiService
export type UserApiServiceType = ReturnType<typeof userApiService>;
