/**
 * @ngdoc service
 * @name momentService
 * @module flowingly.services
 *
 * @description A helper service for api/workflow/ actions.
 * Note that the name workflow is used incorrectly here, it refers to Flow Models.
 *
 */

import IPublishWorkflowModel from '@Shared.Angular/@types/core/api/models/publishWorkflowModel';
import INodeCustomEmailCommandData from '@Shared.Angular/@types/core/contracts/commands/modeler/nodeCustomEmailCommandData';
import Guid from '@Shared.Angular/@types/guid';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IHttpService } from 'angular';
import { FlowModelPublishType } from './flowingly.constants';
import { IFlowModelSummary } from '@Shared.Angular/@types/core/contracts/queryModel/flowModels/flowModelSummary';
import IReportFlowModelDetail from '@Shared.Angular/@types/core/contracts/queryModel/reports/reportFlowModelDetail';

export class FlowModelApiService {
  constructor(
    private $http: IHttpService,
    private APP_CONFIG: Services.APP_CONFIG,
    private flowinglyConstants: Services.FlowinglyConstants
  ) {}

  getFlowModels(noSpinner = false) {
    return this.$http
      .get<IResponseData<IFlowModelSummary[]>>(
        `${this.APP_CONFIG.apiBaseUrl}workflow`,
        {
          noSpinner: noSpinner || false
        }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  getFlowModelById(flowModelId: Guid) {
    return this.$http
      .get(`${this.APP_CONFIG.apiBaseUrl}workflow/${flowModelId}`)
      .then((response) => response.data);
  }

  getPublishedEntities(flowModelId: Guid, noSpinner = false) {
    return this.$http.get(
      `${this.APP_CONFIG.apiBaseUrl}workflow/${flowModelId}/publishedEntities`,
      { noSpinner: noSpinner }
    );
  }

  publishFlowModel(model: IPublishWorkflowModel) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}workflow/${model.flowId}/publish`,
      model
    );
  }

  unPublishFlowModel(flowModelId: Guid) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}workflow/${flowModelId}/unpublish`,
      {}
    );
  }

  getPublishedFlowModel(flowModelId: Guid, noSpinner = false) {
    return this.$http
      .get<IResponseData>(
        `${this.APP_CONFIG.apiBaseUrl}workflow/published/${flowModelId}?` +
          `maxUsersToShow=${this.flowinglyConstants.maxNumberOfUsersToShow}`,
        { noSpinner: noSpinner }
      )
      .then((response) => {
        return response.data.dataModel;
      });
  }

  getPublishedFlowModels(
    noSpinner: boolean,
    publishType: FlowModelPublishType
  ) {
    return this.$http<IResponseData>({
      method: 'GET',
      url: this.APP_CONFIG.apiBaseUrl + 'workflow/published',
      params: {
        publishType: publishType
      },
      noSpinner: noSpinner
    }).then((response) => response.data.dataModel);
  }

  getReportFlowModels(noSpinner = false) {
    return this.$http
      .get<IReportFlowModelDetail[]>(
        this.APP_CONFIG.apiBaseUrl + 'reports/getFlowModelsCanReportOn',
        {
          noSpinner: noSpinner
        }
      )
      .then((response) => {
        return response.data;
      });
  }

  deleteFlowModel(flowModelId: Guid) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}workflow/delete/${flowModelId}`,
      {}
    );
  }

  linkFlowModelToSourceFile(flowModelId: Guid, fileId: Guid) {
    return this.$http.put(
      `${this.APP_CONFIG.apiBaseUrl}workflow/${flowModelId}/source`,
      JSON.stringify(fileId)
    );
  }

  linkImportedFlowModelComponents(publishType: FlowModelPublishType) {
    return this.$http.get(
      `${this.APP_CONFIG.apiBaseUrl}workflow/link/${publishType}`
    );
  }

  sendTestEmail(nodeCustomEmail: INodeCustomEmailCommandData) {
    return this.$http.post(
      `${this.APP_CONFIG.apiBaseUrl}workflow/sendTestEmail/`,
      nodeCustomEmail
    );
  }
}

angular
  .module('flowingly.services')
  .factory('flowModelApiService', [
    '$http',
    'APP_CONFIG',
    'flowinglyConstants',
    (
      $http: IHttpService,
      appConfig: Services.APP_CONFIG,
      flowinglyConstants: Services.FlowinglyConstants
    ) => new FlowModelApiService($http, appConfig, flowinglyConstants)
  ]);

export type FlowModelApiServiceType = InstanceType<typeof FlowModelApiService>;
