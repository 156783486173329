/**
 * @ngdoc service
 * @name guidService
 * @module flowingly.services
 *
 * @description A service for generating unique GUIDS.
 *
 * ## Notes
 *
 * ###API
 * * new - retunrs a new guid
 * * empty - returns an empty guid
 *
 * ###Usage
 *     var id = guidService.new();
 *     if (workflow.Id === guidService.empty())
 *
 * Converted to ts on 14/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/bfbd28aa4d6926c4a028dd26fb3e3facb5178f9a/src/Flowingly.Shared.Angular/flowingly.services/guid.service.js?at=master
 */

'use strict';
import angular from 'angular';

angular.module('flowingly.services').factory('guidService', guidService);

guidService.$inject = [];

function guidService() {
  //API
  const service = {
    new: newGuid,
    empty: emptyGuid,
    isValidGuid: isValidGuid
  };

  return service;

  //////////// Public API Methods

  function newGuid() {
    //source: https://gist.github.com/gmilby/6611969
    //alternateives: http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    function chunk(s?) {
      const p = (Math.random().toString(16) + '000000000').substr(2, 8);
      return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    }
    return chunk() + chunk(true) + chunk(true) + chunk();
  }

  function emptyGuid() {
    return '00000000-0000-0000-0000-000000000000';
  }

  function isValidGuid(val) {
    const regex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return regex.test(val);
  }
}

export type GuidServiceType = ReturnType<typeof guidService>;
