'use strict';
import angular from 'angular';

angular
  .module('flowingly.services')
  .factory('flowinglyFormulaService', flowinglyFormulaService);

flowinglyFormulaService.$inject = [
  'flowinglyModelUtilityService',
  'currencyService',
  'flowinglyConstants'
];

function flowinglyFormulaService(
  flowinglyModelUtilityService,
  currencyService,
  flowinglyConstants
) {
  const mathOperators = ['+', '-', '*', '/'];

  // API
  const service = {
    evaluate: evaluate,
    validate: validate,
    formatCell: formatCell,
    isCurrencyCell: isCurrencyCell,
    isNumberCell: isNumberCell,
    hasSumCell: hasSumCell,
    hasLookupSumCell: hasLookupSumCell
  };

  return service;

  /// PUBLIC API METHODS /////////////////////////////////////////

  function formatCell(formschema, cells, cell) {
    if (cell.type === flowinglyConstants.tableCellType.FORMULA) {
      if (
        cell.value !== undefined &&
        cell.value !== null &&
        cell.value !== ''
      ) {
        const numericValue = Number(cell.value);
        if (
          !isNaN(numericValue) &&
          isFormulaCellType(
            formschema,
            cells,
            cell,
            flowinglyConstants.formFieldType.CURRENCY
          )
        ) {
          return numericValue.toFixed(2);
        }
      }
    }
    return cell.value;
  }

  function isCurrencyCell(formschema, cells, cell) {
    return (
      cell.type === flowinglyConstants.tableCellType.CURRENCY ||
      isLookupCellType(cell, flowinglyConstants.formFieldType.CURRENCY) ||
      isFormulaCellType(
        formschema,
        cells,
        cell,
        flowinglyConstants.formFieldType.CURRENCY
      )
    );
  }

  function isNumberCell(formschema, cells, cell) {
    return (
      cell.type === flowinglyConstants.tableCellType.NUMBER ||
      isLookupCellType(cell, flowinglyConstants.formFieldType.NUMBER) ||
      (isFormulaCellType(
        formschema,
        cells,
        cell,
        flowinglyConstants.formFieldType.NUMBER
      ) &&
        !isFormulaCellType(
          formschema,
          cells,
          cell,
          flowinglyConstants.formFieldType.CURRENCY
        ))
    );
  }

  function hasSumCell(formschema, cells, cell) {
    return (
      isCurrencyCell(formschema, cells, cell) ||
      isNumberCell(formschema, cells, cell)
    );
  }

  function hasLookupSumCell(cell) {
    return (
      isLookupCellType(cell, flowinglyConstants.formFieldType.CURRENCY) ||
      isLookupCellType(cell, flowinglyConstants.formFieldType.NUMBER)
    );
  }

  function evaluate(
    formulaConfig,
    formSchema,
    formData,
    tableSchema = null,
    tableRow = null
  ) {
    const operands = formulaConfig.formulaOperands;
    let formula = formulaConfig.formula;
    formula = formula.replace('=', '');

    let stringDataTypeFound = false;
    for (const operandName of operands) {
      if (
        operandName.indexOf(flowinglyConstants.formulaOperandPrefix.FIELD) !==
          -1 ||
        operandName.indexOf(flowinglyConstants.formulaOperandPrefix.CELL) !== -1
      ) {
        let operandValue = getOperandValue(operandName, formData, tableRow);
        const operandType = getOperandType(
          operandName,
          formSchema,
          tableSchema
        );
        if (operandType.length > 0) {
          if (operandType === flowinglyConstants.formFieldType.TEXT) {
            if (
              operandValue !== undefined &&
              operandValue !== '' &&
              operandValue !== null
            ) {
              stringDataTypeFound = true;
              formula = formula.replace(operandName, new String(operandValue));
            }
          } else if (
            operandType === flowinglyConstants.formFieldType.NUMBER ||
            operandType === flowinglyConstants.formFieldType.CURRENCY
          ) {
            operandValue = ` ${
              operandType == flowinglyConstants.formFieldType.CURRENCY
                ? currencyService.parseNumber(operandValue)
                : parseFloat(operandValue)
            }`;
            if (!isNaN(operandValue)) {
              formula = formula.replace(operandName, operandValue);
            }
          }
        }
      }
    }
    if (
      formula.indexOf(flowinglyConstants.formulaOperandPrefix.FIELD) === -1 &&
      formula.indexOf(flowinglyConstants.formulaOperandPrefix.CELL) === -1
    ) {
      if (stringDataTypeFound) {
        return formula.replace('+', '');
      } else {
        try {
          const result = eval(formula);
          if (!isNaN(result)) {
            return result.toFixed(result % 1 === 0 ? 0 : 2);
          }
        } catch (error) {
          /* empty */
        }
      }
    }

    return null;
  }

  function validate(formulaConfig, formSchema, tableSchema = null) {
    const operands = formulaConfig.formulaOperands;
    let formula = formulaConfig.formula;
    formula = formula.replace('=', '');
    if (formula.trim() === '') return true;

    let stringDataTypeFound = false;
    for (const operandName of operands) {
      if (
        operandName.indexOf(flowinglyConstants.formulaOperandPrefix.FIELD) !==
          -1 ||
        operandName.indexOf(flowinglyConstants.formulaOperandPrefix.CELL) !== -1
      ) {
        const operandType = getOperandType(
          operandName,
          formSchema,
          tableSchema
        );
        if (operandType.length > 0) {
          let replaceVal = null;
          if (operandType === flowinglyConstants.formFieldType.TEXT) {
            stringDataTypeFound = true;
            replaceVal = 't';
          } else if (
            operandType === flowinglyConstants.formFieldType.NUMBER ||
            operandType === flowinglyConstants.formFieldType.CURRENCY
          ) {
            replaceVal = 1;
          } else {
            return false;
          }
          if (replaceVal !== null) {
            formula = formula.replace(operandName, replaceVal);
          }
        } else {
          return false;
        }
      }
    }
    if (stringDataTypeFound) {
      return mathOperators
        .filter((operator) => operator !== '+')
        .every((operator) => formula.indexOf(operator) === -1);
    } else {
      try {
        const outPut = eval(formula);
        return !isNaN(outPut);
      } catch (error) {
        return false;
      }
    }
  }

  /// PRIVATE METHODS //////////////////////////////////

  function getItemType(item) {
    if (
      (item.type === flowinglyConstants.tableCellType.LOOKUP ||
        item.type === flowinglyConstants.formFieldType.LOOKUP) &&
      item.lookupConfig
    ) {
      return item.lookupConfig.displayValueType;
    }
    switch (item.type) {
      case flowinglyConstants.tableCellType.TEXT:
        return flowinglyConstants.formFieldType.TEXT;
      case flowinglyConstants.tableCellType.CURRENCY:
        return flowinglyConstants.formFieldType.CURRENCY;
      case flowinglyConstants.tableCellType.NUMBER:
        return flowinglyConstants.formFieldType.NUMBER;
    }
    return item.type;
  }

  function getCell(operandName, lookup) {
    if (!operandName.startsWith(flowinglyConstants.formulaOperandPrefix.CELL)) {
      return null;
    }
    const cellId = Number(
      operandName.substring(flowinglyConstants.formulaOperandPrefix.CELL.length)
    );
    return lookup(cellId);
  }

  function getOperandValue(operandName, formData, tableRow) {
    const cell = getCell(operandName, (cellId) =>
      tableRow ? tableRow.cells.find((cell) => cell.id === cellId) : null
    );
    if (cell === null) {
      return formData[operandName];
    }
    return cell.value;
  }

  function getOperandType(operandName, formSchema, tableSchema) {
    const cell = getCell(operandName, (cellId) =>
      tableSchema ? tableSchema.find((cell) => cell.id == cellId) : null
    );
    if (cell !== null) {
      return getItemType(cell);
    }

    let allFields = angular.copy(formSchema.fields);
    const tableFields = formSchema.fields.filter(
      (f) => f.type === flowinglyConstants.formFieldType.TABLE
    );

    if (tableFields && tableFields.length > 0) {
      const tableCells = flowinglyModelUtilityService.transformTableCellToField(
        flowinglyModelUtilityService.getEligibleCellsInFields(tableFields)
      );
      allFields = allFields.concat(tableCells);
    }

    const field = allFields.find((field) => field.name === operandName);
    if (field) {
      return getItemType(field);
    }

    return '';
  }

  function isLookupCellType(cell, type) {
    return (
      cell.type === flowinglyConstants.tableCellType.LOOKUP &&
      cell.lookupConfig &&
      cell.lookupConfig.displayValueType === type
    );
  }

  function isFormulaCellType(formschema, cells, cell, type) {
    return (
      cell.type === flowinglyConstants.tableCellType.FORMULA &&
      cell.formulaConfig &&
      cell.formulaConfig.formulaOperands.some((operand) => {
        return (
          isFieldType(formschema, operand, type) ||
          isCellType(formschema, cells, operand, type)
        );
      })
    );
  }

  function isFieldType(formschema, operandname, type) {
    if (
      !operandname.startsWith(flowinglyConstants.formulaOperandPrefix.FIELD)
    ) {
      return false;
    }
    const field = formschema.fields.find((f) => f.name === operandname);
    return (
      field &&
      (field.type === type ||
        (field.type === flowinglyConstants.formFieldType.LOOKUP &&
          field.lookupConfig &&
          field.lookupConfig.displayValueType === type))
    );
  }

  function convertCellTypeToFieldType(type) {
    switch (type) {
      case flowinglyConstants.tableCellType.NUMBER:
        return flowinglyConstants.formFieldType.NUMBER;
      case flowinglyConstants.tableCellType.CURRENCY:
        return flowinglyConstants.formFieldType.CURRENCY;
    }
    return type;
  }

  function isCellType(formschema, cells, operandname, type) {
    if (!operandname.startsWith(flowinglyConstants.formulaOperandPrefix.CELL)) {
      return false;
    }
    const cellId = Number(
      operandname.substring(flowinglyConstants.formulaOperandPrefix.CELL.length)
    );
    const cell = cells.find((c) => c.id === cellId);
    return (
      cell &&
      (convertCellTypeToFieldType(cell.type) === type ||
        (cell.type === flowinglyConstants.tableCellType.LOOKUP &&
          cell.lookupConfig &&
          cell.lookupConfig.displayValueType === type) ||
        (cell.type === flowinglyConstants.tableCellType.FORMULA &&
          isFormulaCellType(formschema, cells, cell, type)))
    );
  }
}

export type FlowinglyFormulaServiceType = ReturnType<
  typeof flowinglyFormulaService
>;
