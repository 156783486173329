import { Services } from '@Shared.Angular/@types/services';

/*
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/033e6a53a0eb1dca26b2ee96acd6ed749ba341e0/src/Flowingly.Shared.Angular/flowingly.components/lookup/lookup.config.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('lookupConfig', {
    bindings: {
      field: '=',
      allFields: '<',
      cells: '<',
      onFieldChange: '&',
      addLookUpCurrentActorOption: '<'
    },
    templateUrl: 'lookup.config.component.tmpl.html',
    controller: [
      '$http',
      '$timeout',
      'APP_CONFIG',
      'customdbService',
      'guidService',
      'kendoHelper',
      '$q',
      'sessionService',
      'flowinglyConstants',
      function (
        $http,
        $timeout,
        APP_CONFIG,
        customdbService,
        guidService,
        kendoHelper,
        $q,
        sessionService: Services.SessionService,
        flowinglyConstants
      ) {
        let $ctrl = this;
        $ctrl.allDbs = [];
        $ctrl.displayValueOptions = [];
        $ctrl.valueOptions = [];
        $ctrl.randomId = guidService.new();
        $ctrl.showLookupCurrentActorOptions = false;

        $ctrl.instance = {};

        const databasesApiBaseUrl = APP_CONFIG.apiBaseUrl + 'customdatabase';

        $ctrl.$onInit = () => {
          $ctrl.isReady = false;
          if ($ctrl.field.lookupConfig === undefined) {
            $ctrl.field.lookupConfig = {
              dbName: '',
              displayValue: '',
              queryValue: '',
              value: undefined
            };
          }

          sessionService
            .getSetting('EnableLookupFieldCurrentActorOptions')
            .then((value) => {
              $ctrl.showLookupCurrentActorOptions =
                (value && value.toLowerCase()) === 'true';

              // FLOW-6927 If EnableLookupFieldCurrentActorOptions is toggeld off then we need to unset the previously set
              // lookup fields that had Current Actor options. By this way at the time of publish validation will trigger and
              // forces the Flow Model Admin to change the value or turn the business setting back on again, which means
              // toggle on and off works as they are intended.
              if (
                $ctrl.field.lookupConfig.value ===
                  flowinglyConstants.lookupPreviousFieldTypeAndText
                    .CurrentActorName ||
                $ctrl.field.lookupConfig.value ===
                  flowinglyConstants.lookupPreviousFieldTypeAndText
                    .CurrentActorEmail
              ) {
                if (!$ctrl.showLookupCurrentActorOptions) {
                  $ctrl.field.lookupConfig.value = undefined;
                }
              }
            });

          $timeout(() => {
            $ctrl.instance.kendoQuery = (
              angular.element('#lookupQueryValue_' + $ctrl.randomId) as any
            )
              .kendoDropDownList({
                dataSource: $ctrl.displayValueOptions,
                dataTextField: 'name',
                dataValueField: 'name',
                clearButton: false
              })
              .data('kendoDropDownList');

            $ctrl.instance.kendoDisplay = (
              angular.element('#lookupDisplayValue_' + $ctrl.randomId) as any
            )
              .kendoDropDownList({
                dataSource: $ctrl.displayValueOptions,
                dataTextField: 'name',
                dataValueField: 'name',
                clearButton: false
              })
              .data('kendoDropDownList');

            $ctrl.instance.kendoDb = (
              angular.element('#lookupDBValue_' + $ctrl.randomId) as any
            )
              .kendoDropDownList({
                dataSource: $ctrl.allDbs,
                dataTextField: 'name',
                dataValueField: 'name',
                clearButton: false
              })
              .data('kendoDropDownList');
          }).then(() => {
            const lookupConfig = $ctrl.field.lookupConfig;
            return $q
              .all([
                customdbService.getAllDatabases(),
                lookupConfig.dbName &&
                  customdbService.getColumns(lookupConfig.dbName)
              ])
              .then(([allDbs, currColumns]) => {
                // work out the valueOptions for filters which are
                // short text|number|dropdown list|option list|currency|email
                // fields before current field
                $ctrl.valueOptions = customdbService.populateValueOptions(
                  { allFields: $ctrl.allFields, cells: $ctrl.cells },
                  $ctrl.field,
                  undefined,
                  needInsertInitiatorOption()
                );
                $ctrl.allDbs = allDbs;

                $ctrl.instance.kendoDb.setDataSource($ctrl.allDbs);
                if (lookupConfig.dbName) {
                  $ctrl.instance.kendoQuery.setDataSource(currColumns);
                  $ctrl.instance.kendoDisplay.setDataSource(currColumns);

                  kendoHelper.selectByCriteria($ctrl.instance.kendoDb, {
                    name: lookupConfig.dbName
                  });

                  if (lookupConfig.queryValue) {
                    kendoHelper.selectByCriteria($ctrl.instance.kendoQuery, {
                      name: lookupConfig.queryValue
                    });
                  }
                  if (lookupConfig.displayValue) {
                    kendoHelper.selectByCriteria($ctrl.instance.kendoDisplay, {
                      name: lookupConfig.displayValue
                    });
                  }
                }
                return $ctrl.populateQueryAndDisplayColumns(); // populate the query/and dispaly columns
              })
              .then(() => {
                $ctrl.isReady = true;
              });
          });
        };

        $ctrl.$onDestroy = function () {
          $ctrl.allDbs = [];
          $ctrl.displayValueOptions = [];
          $ctrl.valueOptions = [];
        };

        $ctrl.onDbNameChange = () => $ctrl.populateQueryAndDisplayColumns();

        $ctrl.populateQueryAndDisplayColumns = (
          suppressSubmitFieldChangeNotification: boolean
        ) => {
          console.log($ctrl.field.displayName + ': enter onDbNameChange');

          // reset values
          $ctrl.displayValueOptions = [];

          if (
            $ctrl.field.lookupConfig.dbName &&
            $ctrl.field.lookupConfig.dbName !== ''
          ) {
            console.log(
              $ctrl.field.displayName +
                `: API getting all columns ${$ctrl.field.lookupConfig.dbName}`
            );
            return $http
              .get(
                `${databasesApiBaseUrl}/columns/${$ctrl.field.lookupConfig.dbName}`,
                { noSpinner: true }
              )
              .then(function (response) {
                let idColumnIndex = response.data.findIndex(
                  (d) => d.name === 'Id'
                );
                let customIdColumnIndex = response.data.findIndex(
                  (d) => d.name === 'customdatabaseid'
                );

                if (idColumnIndex >= 0 && customIdColumnIndex < 0) {
                  response.data.splice(idColumnIndex, 1);
                } else if (customIdColumnIndex >= 0) {
                  response.data.splice(customIdColumnIndex, 1);
                }

                $ctrl.displayValueOptions = response.data;

                setQueryValueOptionAndEnablement({
                  options: $ctrl.displayValueOptions,
                  enable: true
                });

                $timeout(() => {
                  angular
                    .element('#lookupQueryValue_' + $ctrl.randomId)
                    .data('kendoDropDownList')
                    .value($ctrl.field.lookupConfig.queryValue);
                  angular
                    .element('#lookupQueryValue_' + $ctrl.randomId)
                    .data('kendoDropDownList')
                    .text($ctrl.field.lookupConfig.queryValue || '');

                  angular
                    .element('#lookupDisplayValue_' + $ctrl.randomId)
                    .data('kendoDropDownList')
                    .value($ctrl.field.lookupConfig.displayValue);
                  angular
                    .element('#lookupDisplayValue_' + $ctrl.randomId)
                    .data('kendoDropDownList')
                    .text($ctrl.field.lookupConfig.displayValue || '');
                }, 200);

                // work out the valueOptions for filters which are short text/number/dropdown list/option list/currency/email fields before current field
                $ctrl.valueOptions = customdbService.populateValueOptions(
                  { allFields: $ctrl.allFields, cells: $ctrl.cells },
                  $ctrl.field,
                  undefined,
                  needInsertInitiatorOption()
                );
                if (
                  suppressSubmitFieldChangeNotification &&
                  suppressSubmitFieldChangeNotification == true
                ) {
                  return;
                }
                // submit changes
                $ctrl.submitFieldChange({ cell: $ctrl.field });
              });
          } else {
            return $q.resolve();
          }
        };

        $ctrl.onDisplayColumnChange = () => {
          const optionName = $ctrl.field.lookupConfig.displayValue;
          $ctrl.field.lookupConfig.displayValueType = _.chain(
            $ctrl.displayValueOptions
          )
            .find({ name: optionName })
            .get('dataType')
            .value();
          $ctrl.submitFieldChange();
        };

        $ctrl.onQueryColumnChange = () => {
          $ctrl.submitFieldChange();
        };

        $ctrl.submitFieldChange = (isQueryColumn, isDisplayColumn) => {
          if (
            $ctrl.field.lookupConfig.value ===
              flowinglyConstants.lookupPreviousFieldTypeAndText
                .CurrentActorName ||
            $ctrl.field.lookupConfig.value ===
              flowinglyConstants.lookupPreviousFieldTypeAndText
                .CurrentActorEmail
          ) {
            // reset values
            $ctrl.displayValueOptions = [];

            setQueryValueOptionAndEnablement({
              options: $ctrl.displayValueOptions,
              enable: false
            });

            $timeout(() => {
              angular
                .element('#lookupQueryValue_' + $ctrl.randomId)
                .data('kendoDropDownList')
                .value($ctrl.field.lookupConfig.queryValue);
              angular
                .element('#lookupQueryValue_' + $ctrl.randomId)
                .data('kendoDropDownList')
                .text($ctrl.field.lookupConfig.queryValue || '');

              angular
                .element('#lookupDisplayValue_' + $ctrl.randomId)
                .data('kendoDropDownList')
                .value($ctrl.field.lookupConfig.displayValue);
              angular
                .element('#lookupDisplayValue_' + $ctrl.randomId)
                .data('kendoDropDownList')
                .text($ctrl.field.lookupConfig.displayValue || '');
            }, 200);
          } else {
            $ctrl.populateQueryAndDisplayColumns(true);
          }
          if ($ctrl.field) {
            $ctrl.onFieldChange({ cell: $ctrl.field });
          }
        };

        function setQueryValueOptionAndEnablement(config) {
          var queryValueDropdown = angular
            .element('#lookupQueryValue_' + $ctrl.randomId)
            .data('kendoDropDownList');
          if (queryValueDropdown) {
            queryValueDropdown.setDataSource(config.options);
            queryValueDropdown.enable(config.enable);
          }

          var displayValueDropdown = angular
            .element('#lookupDisplayValue_' + $ctrl.randomId)
            .data('kendoDropDownList');
          if (displayValueDropdown) {
            displayValueDropdown.setDataSource(config.options);
            displayValueDropdown.enable(config.enable);
          }

          $timeout(() => {
            angular
              .element('#lookupQueryValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .value($ctrl.field.lookupConfig.queryValue);
            angular
              .element('#lookupQueryValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .text($ctrl.field.lookupConfig.queryValue || '');

            angular
              .element('#lookupDisplayValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .value($ctrl.field.lookupConfig.displayValue);
            angular
              .element('#lookupDisplayValue_' + $ctrl.randomId)
              .data('kendoDropDownList')
              .text($ctrl.field.lookupConfig.displayValue || '');
          }, 200);
        }

        function needInsertInitiatorOption() {
          return {
            addInitiatorOption: $ctrl.field.lookupConfig.dbName === 'Users',
            addCurrentActorOptions:
              $ctrl.field.lookupConfig.dbName === 'Users' &&
              $ctrl.addLookUpCurrentActorOption &&
              $ctrl.showLookupCurrentActorOptions
          };
        }
      }
    ]
  });
})();
