/**
 * @ngdoc service
 * @name BpmnDiagramService
 * @module flowingly.bpmn.modeler
 *
 * @description  A helper service for defining and persisting GoJS Diagrams.
 *
 * ## Notes
 *
 * ###API
 * * getDiagram - Get  previously stored GoJS diagram associated wih the given key
 * * getDiagramModel - Create a new diagram with default properties.
 * * storeDiagram - Store a GoJS diagram with the given key
 * * getLaneResizingTool - return a tool for lane resizing (not used)
 * * relayoutDiagram - ??
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/e5284bf862628ec8ee720a99ca862545a7b5c12e/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.diagram.service.js?at=master
 */

'use strict';
import angular from 'angular';
///
/// A helper service for defining and persisting GoJS Diagrams
///

angular
  .module('flowingly.bpmn.modeler')
  .factory('BpmnDiagramService', bpmnDiagramService);

bpmnDiagramService.$inject = ['goService', 'BPMN_CONSTANTS', 'BpmnService'];

function bpmnDiagramService(goService, BPMN_CONSTANTS, BpmnService) {
  // TODO these should be defined on BPMN_CONSTANTS and/ or within a swimlane service
  //swimlanes
  const MINLENGTH = 400; // this controls the minimum length of any swimlane
  const MINBREADTH = 20; // this controls the minimum breadth of any non-collapsed swimlane

  const diagrams = {};

  const service = {
    getDiagram: getDiagram,
    getDiagramModel: getDiagramModel,
    storeDiagram: storeDiagram,
    getLaneResizingTool: getLaneResizingTool,
    relayoutDiagram: relayoutDiagram
  };
  return service;

  /// PUBLIC ///////////////////////////////////////////////////////////////////////

  ///
  /// Create a new diagram with default properties.
  ///
  function getDiagramModel() {
    const diagram = {
      //maybe we should pass this into directive?
      nodeTemplateMap: BpmnService.getNodeTemplateMap(),
      linkTemplateMap: BpmnService.getLinkTemplateMap(),
      groupTemplateMap: BpmnService.getGroupTemplateMap(),
      allowDrop: true,
      scale: BPMN_CONSTANTS.DiagramScale,
      minScale: BPMN_CONSTANTS.DiagramMinScale,
      maxScale: BPMN_CONSTANTS.DiagramMaxScale,
      commandHandler: BpmnService.getDrawCommandHandler(),
      linkingTool: BpmnService.getBpmnLinkingTool(),
      // default to having arrow keys move selected nodes
      'commandHandler.arrowKeyBehavior': 'move',
      // Prevent undo/redo, but enable undoManager due to required events: FLOW-5376
      allowUndo: false,
      'undoManager.isEnabled': true,
      'draggingTool.isCopyEnabled': false,
      'animationManager.isEnabled': true,
      'animationManager.duration': 1000,
      scrollMode: goService.Diagram.InfiniteScroll
    };
    return diagram;
  }

  ///
  /// Get GoJS diagram associated wih the given key
  ///
  function getDiagram(key) {
    return diagrams[key];
  }

  ///
  /// Store a GoJS diagram with the given key
  ///
  function storeDiagram(key, diagram) {
    diagrams[key] = diagram;
  }

  //TODO THIS SHOULD NOT BE HERE. The best place for this would be the common service, injected into bpmnService
  //and then use from there - see getBpmnLinkingTool()
  function getLaneResizingTool() {
    goService.Diagram.inherit(laneResizingTool, goService.ResizingTool);

    laneResizingTool.prototype.isLengthening = function () {
      return this.handle.alignment === goService.Spot.Right;
    };

    /** @override */
    laneResizingTool.prototype.computeMinSize = function () {
      const lane = this.adornedObject.part;
      // assert(lane instanceof goService.Group && lane.category !== "Pool");
      const msz = computeMinLaneSize(lane); // get the absolute minimum size
      if (this.isLengthening()) {
        // compute the minimum length of all lanes
        const sz = computeMinPoolSize(lane.containingGroup);
        msz.width = Math.max(msz.width, sz.width);
      } else {
        // find the minimum size of this single lane
        const sz = computeLaneSize(lane);
        msz.width = Math.max(msz.width, sz.width);
        msz.height = Math.max(msz.height, sz.height);
      }
      return msz;
    };

    /** @override */
    laneResizingTool.prototype.canStart = function () {
      if (!goService.ResizingTool.prototype.canStart.call(this)) return false;

      // if this is a resize handle for a "Lane", we can start.
      const diagram = diagrams['main'];
      if (diagram === null) return false;
      const handl = this.findToolHandleAt(
        diagram.firstInput.documentPoint,
        this.name
      );
      if (
        handl === null ||
        handl.part === null ||
        handl.part.adornedObject === null ||
        handl.part.adornedObject.part === null
      )
        return false;
      return handl.part.adornedObject.part.category === 'Lane';
    };

    /** @override */
    laneResizingTool.prototype.resize = function (newr) {
      const lane = this.adornedObject.part;
      if (this.isLengthening()) {
        // changing the length of all of the lanes
        lane.containingGroup.memberParts.each(function (lane) {
          if (!(lane instanceof goService.Group)) return;
          const shape = lane.resizeObject;
          if (shape !== null) {
            // set its desiredSize length, but leave each breadth alone
            shape.width = newr.width;
          }
        });
      } else {
        // changing the breadth of a single lane
        goService.ResizingTool.prototype.resize.call(this, newr);
      }
      relayoutDiagram(); // now that the lane has changed size, layout the pool again
    };

    return new laneResizingTool();
  }

  function relayoutDiagram() {
    const myDiagram = diagrams['main'];
    myDiagram.layout.invalidateLayout();
    myDiagram.findTopLevelGroups().each(function (g) {
      if (g.category === 'Pool') g.layout.invalidateLayout();
    });
    myDiagram.layoutDiagram();
  }

  //PRIVATE METHODS ///////////////////////////////////////////////////////////////////
  function laneResizingTool() {
    goService.ResizingTool.call(this);
  }

  // compute the minimum size of a Pool Group needed to hold all of the Lane Groups
  function computeMinPoolSize(pool) {
    // assert(pool instanceof goService.Group && pool.category === "Pool");
    let len = MINLENGTH;
    pool.memberParts.each(function (lane) {
      // pools ought to only contain lanes, not plain Nodes
      if (!(lane instanceof goService.Group)) return;
      const holder = lane.placeholder;
      if (holder !== null) {
        const sz = holder.actualBounds;
        len = Math.max(len, sz.width);
      }
    });
    return new goService.Size(len, NaN);
  }

  // compute the minimum size for a particular Lane Group
  function computeLaneSize(lane) {
    // assert(lane instanceof goService.Group && lane.category !== "Pool");
    const sz = computeMinLaneSize(lane);
    if (lane.isSubGraphExpanded) {
      const holder = lane.placeholder;
      if (holder !== null) {
        const hsz = holder.actualBounds;
        sz.height = Math.max(sz.height, hsz.height);
      }
    }
    // minimum breadth needs to be big enough to hold the header
    const hdr = lane.findObject('HEADER');
    if (hdr !== null) sz.height = Math.max(sz.height, hdr.actualBounds.height);
    return sz;
  }

  // determine the minimum size of a Lane Group, even if collapsed
  function computeMinLaneSize(lane) {
    if (!lane.isSubGraphExpanded) return new goService.Size(MINLENGTH, 1);
    return new goService.Size(MINLENGTH, MINBREADTH);
  }
}

export type BpmnDiagramServiceType = ReturnType<typeof bpmnDiagramService>;
