/*
 * @ngdoc service
 * @name pubsubService
 * @module flowingly.services
 *
 * @description A service for publishing and subcribing to events.
 *
 * ## Notes
 * Events are only fired if:
 * * The event exists in the eventNames array
 * * There is at least one subscriber to that event
 * 
 * ###API
 * * subscriptions - list of all subcribers, organised by event name
 * * subscribe - registers a callback method to be called when the named event is publish
 * * publish - publish an event of given name
 * 
 * ###Usage
 *    
 * pubsubService.subscribe('WORKFLOW_PUBLISHED', onWorkflowPublished, 'workflow.canvas.controller');
 * pubsubService.publish('WORKFLOW_PUBLISHED', {});
 *
 * pubsubService.subscribe('FORM_TABLE_VALID', clearTableError, 'runnerCardController', $ctrl.formErrors);
 * 
 *     function setTableError(event, data, param) {
                  param.table = undefined;
                  param.table = true;
       }


* Converted to ts on 14/01/2020
* See https://bitbucket.org/flowingly-team/flowingly-source-code/src/be47d178bb5e304e72ef443ecfbee02850192a8d/src/Flowingly.Shared.Angular/flowingly.services/pubSub.service.js?at=master
*/

'use strict';
import {
  Callback,
  PendingEvent,
  PubSubEvent,
  PubSubService,
  allPubSubEvents,
  isSignalrEvent,
  isSystemEvent
} from '@Shared.Angular/@types/pubSub';
import { Services } from '@Shared.Angular/@types/services';
import angular, { ITimeoutService } from 'angular';
angular.module('flowingly.services').factory('pubsubService', pubsubService);

pubsubService.$inject = ['$timeout', 'lodashService', 'devLoggingService'];

function pubsubService(
  $timeout: ITimeoutService,
  lodashService: Lodash,
  devLoggingService: Services.DevLoggingService
) {
  const { info: logInfo, error: logError } = devLoggingService;
  const { pullAllBy } = lodashService;
  const debouncedEventTimeoutIds = {}; // keep track of timeoutIds of events being published

  let isConnectionReady = false;
  let pendingEvents: PendingEvent[] = [];

  const service: PubSubService = {
    subscriptions: {}, // event subscriptions that are broadcasted.
    subscribe,
    publish,
    unsubscribeAll
  };

  initialise();

  return service;

  function subscribe(
    event: PubSubEvent,
    callback: Callback,
    subscriberId: string,
    param?: unknown
  ) {
    ///
    /// Subscribe for notifications of an event
    /// event - the event name to subscribe to
    /// callback - method to call when this event is published
    /// subscriberId - optional subscriber id to prevent multiple subscriptions
    /// param - optional param that will be passed to the callback method when it is invoked
    ///
    //allow subscription only for known events

    const subscriber = {
      id: subscriberId,
      callback,
      param
    };
    const subsribers = service.subscriptions[event];
    //If an id was provided, remove the old subscriptionso that it can be re-added
    //It is important we re-add it as the previous subscription might relate to an object that has been destroyed
    if (subscriberId) {
      pullAllBy(subsribers, [{ id: subscriberId }], 'id');
    }
    //subscribe
    subsribers.push(subscriber);
  }

  /**
   * Execute all callbacks subsribed to the event
   * @param event
   * @param data
   */
  function execCallbacks(event: PubSubEvent, data: unknown) {
    //capture this state of the subscriptions before timeout
    //in case they change before timeout activates (on state chnage);
    const subscribers = service.subscriptions[event];

    $timeout(function () {
      logInfo('publishing event ' + event);

      subscribers.forEach((subscriber) => {
        const { id, callback, param } = subscriber;

        if (
          (isConnectionReady || !isSignalrEvent(event)) &&
          angular.isFunction(callback)
        ) {
          try {
            //call the callback registered with the event & data
            logInfo(`subscriber ${id} for event ${event} executing`);
            callback(event, data, param);
          } catch (e) {
            logError(e);
          }
        } else {
          if (!isConnectionReady) {
            // pending list contains non-duplicated event only
            const pendingEvent = pendingEvents.find(
              (evt) => evt.event === event
            );
            if (pendingEvent) {
              pendingEvent.data = data;
            } else {
              pendingEvents.push({
                event,
                data
              });
            }
          }
        }
      });
    }, 0);
  }

  /**
   * Publish an event to all subscribers
   * systemEvent no debounced when publish
   * signalR event will be debounced by 1.5s
   * other non system events will be debounced by 300ms
   */
  function publish(event: PubSubEvent, data: unknown = null) {
    // no debouncing for system events
    if (isSystemEvent(event)) {
      execCallbacks(event, data);
      return;
    }

    let timeoutId = debouncedEventTimeoutIds[event];

    if (timeoutId) {
      $timeout.cancel(timeoutId);
    }

    const waitMs = isSignalrEvent(event) ? 1500 : 300;

    // delay executing the callback by waitMs
    timeoutId = $timeout(() => {
      debouncedEventTimeoutIds[event] = null;
      // the wait is over. executing subscriber callback for the event
      execCallbacks(event, data);
    }, waitMs);

    debouncedEventTimeoutIds[event] = timeoutId;
  }

  //finds all subscriptions across all events that match this id and removes them
  function unsubscribeAll(subscriberId: string) {
    const { subscriptions } = service;
    for (const event in subscriptions) {
      const subscribers = subscriptions[event as PubSubEvent];
      if (subscribers.length > 0) {
        pullAllBy(subscribers, [{ id: subscriberId }], 'id');
      }
    }
  }

  ///
  /// private methods
  ///

  function initialise() {
    const subscriberId = 'runner.signalR.client';
    const signalrConnected = () => {
      isConnectionReady = true;
      //execute all pending publish as now connection is established
      pendingEvents.forEach(({ event, data }) => publish(event, data));
      pendingEvents = [];
    };
    const signalrFailed = () => (isConnectionReady = false);

    //we first register known events that can be subscribed to
    allPubSubEvents.forEach((event) => (service.subscriptions[event] = []));
    subscribe('SIGNALR_CONNECTED', signalrConnected, subscriberId);
    subscribe('SIGNALR_CONNECT_FAILED', signalrFailed, subscriberId);
  }
}

// get type of pubsubService
export type PubSubServiceType = ReturnType<typeof pubsubService>;
