'use strict';
import angular from 'angular';
import { BpmnModeler } from './@types/services';
import { Services } from '@Shared.Angular/@types/services';
//import { FlowGoJS as FlowGoJs } from '../flowingly.adapters/go';

/**
 * @ngdoc directive
 * @name goBpmnDiagram
 * @module flowingly.modeler.directives
 * @description  A directive that represents a GoJS diagram.
 * @usage
 * ```
       <go-bpmn-diagram id="mainDiagram" go-model="ctrl.model.goModel" style=""></go-bpmn-diagram>
 * ```
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/f28945bb6833b690e6cf8b9b5bfd3a7c810946a6/src/Flowingly.Shared.Angular/flowingly.bpmn.modeler/flowingly.bpmn.diagram.directive.js?at=master
 */

angular.module('flowingly.bpmn.modeler').directive('goBpmnDiagram', [
  'BPMN_CONSTANTS',
  '$rootScope',
  '$timeout',
  '$window',
  'goService',
  'pubsubService',
  'BpmnService',
  'BpmnDiagramService',
  'guidService',

  'lodashService',
  'validationService',
  'flowinglyConstants',
  function (
    BPMN_CONSTANTS: BpmnModeler.BpmnConstants,
    $rootScope: angular.IRootScopeService,
    $timeout: angular.ITimeoutService,
    $window: angular.IWindowService,
    goService: GoJS,
    pubsubService: Services.PubSubService,
    BpmnService: BpmnModeler.BpmnService,
    BpmnDiagramService: BpmnModeler.BpmnDiagramService,
    guidService: Services.GuidService,
    lodash: Lodash,
    validationService: Services.ValidationService,
    flowinglyConstants: Services.FlowinglyConstants
  ) {
    return {
      restrict: 'E',
      template: '<div></div>',
      replace: true,
      scope: {
        model: '=goModel',
        isReadonly: '<?'
      },
      link: function (scope, element: any, attrs) {
        //------------------------------------------the main Diagram----------------------------------------------

        const $GO = goService.GraphObject.make;

        const dg = BpmnDiagramService.getDiagramModel();
        dg.initialContentAlignment = goService.Spot.Center; // center the content

        const diagram =
          // create a Diagram for the given HTML DIV element (we are using a direct reference)
          $GO(goService.Diagram, element[0], dg);

        //TAG: BackLinks
        diagram.validCycle = goService.Diagram.CycleAll;

        if (scope.isReadonly) {
          diagram.isReadOnly = true;
          diagram.toolManager.panningTool.isEnabled = false;
        }

        diagram.addDiagramListener('InitialLayoutCompleted', function (e) {
          const diagram = e.diagram;
          ($window as any).diagram = diagram;
          zoomToFit(diagram);

          $timeout(() => {
            // FLOW-4514 Fix: for when the image async load does not trigger an update for gojs
            console.log('Start InitialLayoutCompleted updateAllTargetBindings');

            diagram.startTransaction('UpdateAllTargetBindings_Update');
            diagram.updateAllTargetBindings();
            diagram.commitTransaction('UpdateAllTargetBindings_Update');

            console.log(
              'Commit InitialLayoutCompleted updateAllTargetBindings'
            );
          }, 100);
        });

        // FLOW-2532 Enable snap to grid in modeler
        diagram.toolManager.draggingTool.isGridSnapEnabled = true;
        diagram.toolManager.resizingTool.isGridSnapEnabled = true;

        // FLOW-2566 Prevent links being drawn between parallel pathway nodes
        function customizeValidation(fromNode, fromPort, toNode, toPort) {
          const context = {
            fromNode,
            toNode,
            fromPort,
            toPort,
            nodeDataArray: diagram.model.nodeDataArray,
            linkDataArray: diagram.model.linkDataArray
          };

          if (
            validationService.isLinkDisallowedForGateway(
              fromNode,
              toNode,
              diagram.model.nodeDataArray,
              diagram.model.linkDataArray
            ) ||
            !validationService.isLinkAllowedForNode(context) ||
            validationService.isLinkDisallowedForStartOrEndNode(
              fromNode,
              toNode
            )
          ) {
            return false;
          }

          return true;
        }
        diagram.toolManager.linkingTool.linkValidation = customizeValidation;
        diagram.toolManager.relinkingTool.linkValidation = customizeValidation;

        //store the diagram so that we can later retrieve it in the overview directive
        BpmnDiagramService.storeDiagram('main', diagram);
        //store it on the window so that it's accessible everywhere.
        //as per the bpmn js here: https://gojs.net/latest/extensions/BPMN.js
        $window.mainDiagram = diagram;

        //------------------------------------------end Diagram-------------------------------------------------------

        //------------------------------------------DIAGRAM INIT -----------------------------------------------------

        function zoomToFit(diagram) {
          //previoulsy used, dg.initialAutoScale = go.Diagram.Uniform, but this results in small diagrams zoomed in too much
          //and thus the tasks were displayed really large.

          if (diagram.width <= 10) {
            //this method fires a few times before the diagram is actually displayed
            return;
          }
          const maxZoom = 0.8; //we dont want large tasks
          const minZoom = 0.4; //or teeny tiny ones

          //get diagram bounds
          const diagramWidth = diagram.documentBounds.width;
          const diagramHeight = diagram.documentBounds.height;

          //get the canvas bounds
          const width = element.width();
          const height = element.height();

          if (diagramWidth > width || diagramHeight > height) {
            //calculate new scale as a ratio
            //use the smallest of width and height ratio
            const wRatio = width / diagramWidth;
            const hRatio = height / diagramHeight;
            diagram.scale = wRatio < hRatio ? wRatio : hRatio;

            if (diagram.scale > maxZoom) {
              //dont zoom in anymore than this
              diagram.scale = maxZoom;
            } else {
              //zoom out a little to ensure fits
              diagram.scale = diagram.scale - 0.1;
            }

            if (diagram.scale < minZoom) {
              //dont zoom out more than this
              diagram.scale = minZoom;
            }
          } else {
            //default zoom
            diagram.scale = maxZoom;
          }

          // Business requirement https://bizflo.atlassian.net/browse/FLOW-4523
          const distanceFromPalette = BPMN_CONSTANTS.palette.outerWidth * 2;
          const iconHalfWidth = BPMN_CONSTANTS.palette.icon.outerWidth / 2;
          diagram.position.x =
            diagram.documentBounds.x - distanceFromPalette - iconHalfWidth;
        }

        // Set backlinkTo on backlink fromNode, so that backlink color can be decided when getLinkTemplate
        function setBacklinkToOnNodes(model) {
          const links = model.linkDataArray;
          const nodes = model.nodeDataArray;
          if (!links || !nodes) {
            return;
          }

          const backlinks = links.filter((l) => l.isBacklink);
          backlinks.forEach((backlink) => {
            const fromNode = nodes.find((n) => n.key === backlink.from);
            if (fromNode) {
              fromNode.backlinkTo = backlink.to;
            }
          });
        }

        // NOTIFY //////////////////////////////////////////////////////////////////////////////////
        //
        // Anything in this section notifies the outside world of changes within diagram

        // whenever a GoJS transaction has finished modifying the model,
        // notify any link changes, and update all Angular bindings
        function onDiagramModelChanged(event) {
          if (event.isTransactionFinished) {
            const transaction = event.object;
            if (transaction === null) {
              return;
            }

            const isUndo = event.oldValue === 'Undo';
            let notifyChange = false;
            // iterate over all of the actual ChangedEvents of the Transaction
            transaction.changes.each(function (e) {
              switch (e.modelChange) {
                case 'linkDataArray':
                  if (
                    e.change === goService.ChangedEvent.Insert ||
                    (e.change === goService.ChangedEvent.Remove && isUndo)
                  ) {
                    pubsubService.publish('DIAGRAM_LINK_INSERTED', {
                      link: e.newValue
                    });
                    notifyChange = true;
                  } else if (
                    e.change === goService.ChangedEvent.Remove ||
                    (e.change === goService.ChangedEvent.Insert && isUndo)
                  ) {
                    pubsubService.publish('DIAGRAM_LINK_DELETED', {
                      link: e.oldValue
                    });
                    notifyChange = true;
                  }
                  break;

                case 'nodeDataArray':
                  if (
                    e.change === goService.ChangedEvent.Insert ||
                    e.change === goService.ChangedEvent.Remove
                  ) {
                    notifyChange = true;
                  }
                  break;
              }
            });
            // Raise a change when model first created, so validation fires
            if (notifyChange) {
              // update angular bindings
              safeScopeApply(false, false); //Selected node has not changed
            }
          }
        }

        // notice when the value of "model" changes: update the Diagram.model
        scope.$watch('model', function (newmodel: any) {
          const oldmodel = diagram.model;
          if (oldmodel !== newmodel) {
            if (oldmodel) {
              oldmodel.removeChangedListener(onDiagramModelChanged);
            }
            $timeout(function () {
              setBacklinkToOnNodes(newmodel);
              diagram.model = newmodel;
            });
            newmodel.addChangedListener(onDiagramModelChanged);
          }
        });

        // RESPOND ////////////////////////////////////////////////////////////////////////////////
        //
        // Anything in this section responds to events both inside and outside the diagram

        pubsubService.subscribe(
          'WORKFLOW_NODE_CHANGED',
          updateNode,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEP_TYPE_CHANGED',
          updateStepType,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'NOTIFY_INITIATOR_CHANGED',
          updateEmailIcon,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'PUBLICFORM_SETTING_CHANGED',
          updatePublicFormIcon,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEP_REF_SEQUENCE_CHANGED',
          updateStepRefSequence,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEP_ACTOR_CHANGED',
          updateStepActor,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'GATEWAY_DEFAULT_GATE_CHANGED',
          setLinkDefaultFlow,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'TASK_PANEL_OPENCLOSE',
          redrawDiagram,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'ADD_NEW_SWIM_LANE',
          addNewSwimLane,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'REMOVE_SWIM_LANE',
          removeSwimLane,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEPRULE_SETTING_CHANGED',
          updateStepRuleIcon,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEP_INTEGRATION_SETTING_CHANGED',
          updateStepIntegrationIcon,
          'bpmn.diagram.directive'
        );
        pubsubService.subscribe(
          'STEP_TASK_ENABLED_CHANGED',
          updateStepTaskIcon,
          'bpmn.diagram.directive'
        );

        function markDIagramAsDirty() {
          $('#body-content').addClass('changed-input');
        }
        function raiseSelectionChanged() {
          pubsubService.publish('DIAGRAM_NODE_SELECTED', {
            node: diagram.model.selectedNodeData
          });
        }

        function raiseDiagramUpdatedEvent() {
          updateNodeLinks();
          pubsubService.publish('DIAGRAM_MODIFIED');
        }

        diagram.addDiagramListener('BackgroundSingleClicked', function (e) {
          pubsubService.publish('DIAGRAM_CLICKED');
        });

        diagram.addDiagramListener('ExternalObjectsDropped', function (e) {
          onDroppedEvent(e.subject.first().data);
        });

        diagram.addDiagramListener('InitialLayoutCompleted', function (e) {
          pubsubService.publish('DIAGRAM_LOADED');
        });

        // to allow the confirm box You haven't saved your changes to show when diagram is modified
        diagram.addDiagramListener('Modified', function (e) {
          markDIagramAsDirty();
        });

        //if the diagram position has been moved - save it.
        diagram.addDiagramListener('ViewportBoundsChanged', function (e) {
          if (
            e.diagram.position.M !== 'NaN' &&
            e.diagram.position.M != undefined
          ) {
            raiseDiagramUpdatedEvent();
          }
        });

        function safeScopeApply(selectionChanged, isGateway) {
          if (
            $rootScope.$GOphase !== '$apply' &&
            $rootScope.$GOphase !== '$digest'
          ) {
            if (selectionChanged) {
              raiseSelectionChanged();
              if (isGateway) {
                raiseDiagramUpdatedEvent();
              }
            } else {
              raiseDiagramUpdatedEvent();
            }
            scope.$apply();
          }
        }

        diagram.addDiagramListener('LinkRelinked', function (e) {
          pubsubService.publish('DIAGRAM_LINK_RELINKED', {
            link: e.subject,
            portDisconnectedFrom: e.parameter
          });
        });

        // We need to reinitialise the gateway when links are deleted - may as well do it whenever anythiung is deleted to be sure
        diagram.addDiagramListener('SelectionDeleted', function (e) {
          pubsubService.publish('DIAGRAM_PARTS_DELETED');
        });
        diagram.addDiagramListener('ClipboardPasted', (e) => {
          e.subject.each((part) => {
            if (part instanceof goService.Node) {
              e.diagram.model.removeChangedListener(onDiagramModelChanged);
              e.diagram.model.startTransaction('Updating Copied Names');
              part.updateTargetBindings('text');
              e.diagram.model.commitTransaction('Updating Copied Names');
              e.diagram.model.addChangedListener(onDiagramModelChanged);
            }
          });
        });
        // update the model when the selection changes
        diagram.addDiagramListener('ChangedSelection', function (e) {
          const selnode = diagram.selection.first();
          let selNodeData =
            selnode instanceof goService.Node ? selnode.data : null;
          let isGateway = false;

          diagram.model.selectedNodeData = selNodeData;

          // fix FLOW-2419 Modeler - Copy and Paste node, no card
          // if comes from copy paste routine, need tell Modeler side, then can deal with the node information.
          // As now that is saved in the Nodes array, gojs side know nothing about it
          if (
            diagram.model.nodeIdInClipboard &&
            diagram.model.isPasteFromClipboard
          ) {
            diagram.model.selectedNodeData.copiedFromNodeId =
              diagram.model.nodeIdInClipboard;
            diagram.model.selectedNodeData.copiedFromNodeKey =
              diagram.model.nodeKeyInClipboard;
            delete diagram.model.selectedNodeData.backlinkTo;
            diagram.model.isPasteFromClipboard = false;
          }

          if (
            selNodeData !== null &&
            selnode !== null &&
            selnode !== undefined
          ) {
            if (lodash.includes(selnode.category.toLowerCase(), 'gateway')) {
              // Is a gateway so unfotunately we need to raise the diagram updated even to
              // ensure that the gates are handled properly. This is a major problem here ...
              // ToDo: we must break this down and clean up Modeler - we are relying on the
              // need for events to be raised which do "god knows what"! Its crazy shit ...
              isGateway = true;
            }
          }

          if (selNodeData == null) {
            selNodeData =
              selnode instanceof goService.Link ? selnode.data : null;
            if (selNodeData) {
              diagram.model.selectedNodeData = selNodeData;

              const link = diagram.findLinkForData(selNodeData);
              if (
                selNodeData.visible === true &&
                link &&
                link.fromNode.category === 'exclusiveGateway'
              ) {
                selNodeData.category = 'link';
              } else {
                selNodeData.category = '';
              }
            }
          }

          safeScopeApply(true, isGateway); //Selected node has changed + its a gateway
        });

        diagram.toolManager.mouseDownTools.insertAt(
          0,
          BpmnDiagramService.getLaneResizingTool()
        );

        function onDroppedEvent(prop) {
          prop.id = guidService.new();
          prop.isNew = true;
          switch (prop.category) {
            case 'activity':
              diagram.startTransaction('update node model');
              prop.Card = BpmnService.getDefaultActivityCardModel();
              diagram.commitTransaction('update node model');
              break;

            case 'gateway':
              //do nothing right about now
              break;
          }

          pubsubService.publish('DIAGRAM_ELEMENT_DROPPED', prop);
        }

        // Invalidate the layout so it will be redrawn. We do this
        // as when we select nodes we hide/show the rhs drawer
        function redrawDiagram(event, data) {
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);

            diagram.layout.invalidateLayout();
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function setLinkDefaultFlow(event, data) {
          //use timeout to prevent glitch whereby the linkData array doubles in length
          //after this code runs in re
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('setLinkDefaultFlow');

            for (let i = 0; i < data.links.length; i++) {
              const linkData = data.links[i];

              linkData.isDefault = null;
              if (
                linkData.Conditions &&
                linkData.Conditions[0].Type === 'Otherwise'
              ) {
                linkData.isDefault = true;
              }

              const link = diagram.findLinkForData(linkData);
              link.updateTargetBindings('text');
              link.updateTargetBindings('visible');
              link.updateTargetBindings('isDefault');
            }

            diagram.commitTransaction('setLinkDefaultFlow');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function updateStepType(event, data) {
          $timeout(function () {
            const node = diagram.findNodeForData(data.node);

            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateStepType');

            switch (data.node.stepType) {
              case flowinglyConstants.stepType.TASK:
                node.data.taskType = flowinglyConstants.taskType.TASK;
                break;
              case flowinglyConstants.stepType.PUBLIC_FORM:
                node.data.taskType = flowinglyConstants.taskType.PUBLIC_FORM;
                break;
              case flowinglyConstants.stepType.APPROVAL:
                node.data.taskType = flowinglyConstants.taskType.APPROVAL;
                break;
              case flowinglyConstants.stepType.PARALLEL_APPROVAL:
                node.data.taskType =
                  flowinglyConstants.taskType.PARALLEL_APPROVAL;
                break;
              case flowinglyConstants.stepType.SEQUENTIAL_APPROVAL:
                node.data.taskType =
                  flowinglyConstants.taskType.SEQUENTIAL_APPROVAL;
                break;
            }

            node.data.stepType = data.node.stepType;
            diagram.model.setDataProperty(
              data.node,
              'taskType',
              node.data.taskType
            );

            node.updateTargetBindings('taskType');
            diagram.commitTransaction('updateStepType');
            diagram.model.addChangedListener(onDiagramModelChanged);
            // We need to be careful that we do not add any code in future that changes the step type in the DIAGRAM_MODIFIED event
            // for obvious reasons
            raiseDiagramUpdatedEvent(); //Need to notify that the diagram has changed
          });
        }

        function updateNodeLinks() {
          const it = diagram.links.iterator;
          while (it.next()) {
            const ht = it.value.routeBounds.height;
            if (ht !== 0 && ht < 5) {
              const pt = it.value.points.iterator;
              pt.next();
              const initialpoint = pt.value;
              while (pt.next()) {
                pt.value.y = initialpoint.y;
              }
              it.value.movePoints(0, 0);
            }
          }
        }

        function updateEmailIcon(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateDisplayNotificationIcon');

            const node = diagram.findNodeForData(data.node);
            if (node != undefined) {
              node.data.displayNotificationIcon =
                data.node.displayNotificationIcon;
              node.updateTargetBindings('displayNotificationIcon');
            }
            diagram.commitTransaction('updateDisplayNotificationIcon');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function updatePublicFormIcon(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateDisplayPublicFormIcon');

            const node = diagram.findNodeForData(data.node);
            if (node != undefined) {
              node.data.displayPublicFormIcon = data.node.displayPublicFormIcon;
              node.updateTargetBindings('displayPublicFormIcon');
            }
            diagram.commitTransaction('updateDisplayPublicFormIcon');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
            updateStepRuleIcon(event, data); //we also call it here as as of now, Public Form and Step Rules are mutually exclusive
            updateStepIntegrationIcon(event, data);
          });
        }

        function updateStepRuleIcon(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateDisplayStepRuleIcon');

            const node = diagram.findNodeForData(data.node);
            if (node != undefined) {
              node.data.displayStepRuleIcon =
                data.node.displayStepRuleIcon &&
                !data.node.displayPublicFormIcon;
              node.updateTargetBindings('displayStepRuleIcon');
            }
            diagram.commitTransaction('updateDisplayStepRuleIcon');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
            updateStepIntegrationIcon(event, data);
          });
        }

        function updateStepIntegrationIcon(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateStepIntegrationIcon');

            const node = diagram.findNodeForData(data.node);
            if (node != undefined) {
              node.data.displayStepIntegrationIcon =
                data.node.displayStepIntegrationIcon;
              node.updateTargetBindings('displayStepIntegrationIcon');
            }
            diagram.commitTransaction('updateDisplayStepIntegrationIcon');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function updateStepTaskIcon(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);
            diagram.startTransaction('updateStepTaskIcon');

            const node = diagram.findNodeForData(data.node);
            if (node != undefined) {
              node.data.displayStepTaskIcon = data.node.displayStepTaskIcon;
              node.updateTargetBindings('displayStepTaskIcon');
            }

            diagram.commitTransaction('updateStepTaskIcon');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function updateStepRefSequence(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);

            diagram.startTransaction('updateStepRefSequence');

            data.nodes
              .filter(
                (n) => n.category === 'activity' || n.category === 'component'
              )
              .forEach((n) => {
                const node = diagram.findNodeForKey(n.key);
                if (node != undefined) {
                  node.updateTargetBindings('refSequence');
                }
              });

            diagram.commitTransaction('updateStepRefSequence');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function updateStepActor(event, data) {
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);

            diagram.startTransaction('updateStepActor');
            const node = diagram.findNodeForKey(data.node.key);
            if (node != undefined) {
              node.updateTargetBindings('actorName');
              node.updateTargetBindings('avatarUrl');
            }
            diagram.commitTransaction('updateStepActor');
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        ///
        /// Update the node content in response to outside change
        ///
        function updateNode(event, data) {
          markDIagramAsDirty();
          //Use timeout to avoid digest in progress error
          $timeout(function () {
            diagram.model.removeChangedListener(onDiagramModelChanged);

            diagram.startTransaction('updateNode');
            let node = diagram.findNodeForData(data.firstNode);
            if (node != undefined) {
              node.updateTargetBindings('text');
              node.updateTargetBindings('actor');
              node.updateTargetBindings('description');
              node.updateTargetBindings('stepType');
              node.updateTargetBindings('color'); // Lane background color
              node.updateTargetBindings('fontSize'); // Pool or Lane lable font Size
            }
            diagram.commitTransaction('updateNode');

            if (data.nextNode !== null) {
              // We also deal here with any updates to the next node also
              // eg a gateway where a field in the prior node has a changed name that it is dependant on
              diagram.model.removeChangedListener(onDiagramModelChanged);

              diagram.startTransaction('updateNextNode');
              node = diagram.findNodeForData(data.nextNode);
              if (node != undefined) {
                node.updateTargetBindings('text');
                node.updateTargetBindings('actor');
                node.updateTargetBindings('description');
                node.updateTargetBindings('stepType');
              }
              diagram.commitTransaction('updateNextNode');
            }
            updateNodeLinks();
            diagram.model.addChangedListener(onDiagramModelChanged);
          });
        }

        function addNewSwimLane(event, lane) {
          diagram.model.removeChangedListener(onDiagramModelChanged);
          diagram.startTransaction('addLane');
          if (lane !== null && lane.category === 'Lane') {
            // create a new lane data object
            const laneObj = diagram.findNodeForData(lane);
            const shape = laneObj.findObject('SHAPE');
            const size = new goService.Size(shape.width, 20);
            //size.height = MINBREADTH;
            const newlanedata = {
              category: 'Lane',
              text: 'New Lane',
              color: '#fff',
              id: guidService.new(),
              isGroup: true,
              loc: goService.Point.stringify(
                new goService.Point(laneObj.location.x, laneObj.location.y + 1)
              ), // place below selection
              size: goService.Size.stringify(size),
              group: laneObj.data.group
            };
            // and add it to the model
            diagram.model.addNodeData(newlanedata);
          } else if (lane !== null && lane.category === 'Pool') {
            const newlanedatawithLoc = {
              category: 'Lane',
              text: 'New Lane',
              color: '#fff',
              id: guidService.new(),
              isGroup: true,
              size: goService.Size.stringify(new goService.Size(20, 20)),
              group: lane.key
            };
            diagram.model.addNodeData(newlanedatawithLoc);
          }
          diagram.commitTransaction('addLane');
          diagram.model.addChangedListener(onDiagramModelChanged);
        }

        function removeSwimLane(event, lane) {
          diagram.model.removeChangedListener(onDiagramModelChanged);
          diagram.startTransaction('removeLane');
          const laneNode = diagram.findNodeForKey(lane.key);
          if (laneNode.memberParts.count > 0) {
            diagram.commandHandler.addTopLevelParts(laneNode.memberParts, true);
          }
          diagram.remove(laneNode);
          diagram.commitTransaction('removeLane');
          diagram.model.addChangedListener(onDiagramModelChanged);
        }

        //------------------------------------------END DIAGRAM INIT -----------------------------------------------------

        // ----------------------------------------- DIAGRAM MENU COMMAND HANDLERS ----------------------------------------------

        scope.$on('go.event', function (event, eventData) {
          switch (eventData.type) {
            case 'rename':
              rename(eventData.data);
              event.preventDefault();
              break;
          }
        });

        function rename(data) {
          diagram.startTransaction('rename');
          const newName = prompt('Rename ' + data.item + ' to:');
          diagram.model.setDataProperty(data, 'item', newName);
          diagram.commitTransaction('rename');
        }

        // ----------------------------------------- MAIN MENU COMMAND HANDLERS ----------------------------------------------

        scope.$on('go.command', function (event, data) {
          switch (data) {
            case 'copy':
              diagram.commandHandler.copySelection();
              event.preventDefault();
              break;

            case 'paste':
              diagram.commandHandler.pasteSelection();
              event.preventDefault();
              break;

            case 'cut':
              diagram.commandHandler.cutSelection();
              event.preventDefault();
              break;

            case 'delete':
              diagram.commandHandler.deleteSelection();
              event.preventDefault();
              break;

            case 'selectAll':
              diagram.commandHandler.selectAll();
              event.preventDefault();
              break;

            case 'undo':
              diagram.commandHandler.undo();
              event.preventDefault();
              break;

            case 'redo':
              diagram.commandHandler.redo();
              event.preventDefault();
              break;

            case 'alignLeft':
              diagram.commandHandler.alignLeft();
              event.preventDefault();
              break;

            case 'alignRight':
              diagram.commandHandler.alignRight();
              event.preventDefault();
              break;

            case 'alignTop':
              diagram.commandHandler.alignTop();
              event.preventDefault();
              break;

            case 'alignBottom':
              diagram.commandHandler.alignBottom();
              event.preventDefault();
              break;

            case 'alignCenterX':
              diagram.commandHandler.alignCenterX();
              event.preventDefault();
              break;

            case 'alignCenterY':
              diagram.commandHandler.alignCenterY();
              event.preventDefault();
              break;
          }
        });

        // ----------------------------------------- END COMMAND HANDLERS ----------------------------------------------
      }
    };
  }
]);
