'use strict';
import Guid from '@Shared.Angular/@types/guid';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IPromise, IQService } from 'angular';

class SelectUserController {
  //bindings
  ngModel: any;
  exclude: string | string[];
  include: string | string[];
  businessId: string;
  isDisabled: boolean;
  noEmpty: boolean;
  onSelect: any;
  onChange: any;
  onClose: any;
  onOpen: any;
  onDataLoadError: () => void;
  loadUser: (userId: Guid, fieldName: string, options: any) => IPromise<IUserBasicInfo>;

  svcs: {
    $q: IQService;
    lodashService: Lodash;
    userApiService: Services.UserApiService;
    sessionService: Services.SessionService;
  };

  constructor(
    $q: IQService,
    lodashService: Lodash,
    userApiService: Services.UserApiService,
    sessionService: Services.SessionService
  ) {
    this.svcs = {
      userApiService,
      sessionService,
      lodashService,
      $q
    };
    this.fetchUsers = this.fetchUsers.bind(this);
    this.$onChanges = this.$onChanges.bind(this);
    this.multipleStandardsAdapter = this.multipleStandardsAdapter.bind(this);
  }

  $onChanges() {
    this.ngModel = this.multipleStandardsAdapter(this.ngModel);
  }

  /**
   * Different APIs return different standards of these values
   * So we do a catch all here and handle the display and
   * value ourselves.
   *
   * NOTE: {} is not blank. Whne combined with no-empty,
   * the behavior is that it is initially empty, then if you
   * select 1, you are unable to go back to the empty state
   */
  multipleStandardsAdapter(user) {
    if (user) {
      user._selectValue = user.Id || user.id;
      user._selectText =
        user.FullName ||
        user.fullName ||
        (user.firstName && `${user.firstName} ${user.lastName}`) ||
        '';
      return user;
    }
  }

  fetchUsers({ term }: { term: string }) {
    const { $q, lodashService, userApiService } = this.svcs;
    const { differenceWith, isArray, toLower, sortedUniqBy, sortBy, concat } =
      lodashService;

    const fetchUsers = [];
    fetchUsers.push(
      userApiService.searchUsers({ term, clientId: this.businessId })
    );

    if (this.include) {
      fetchUsers.push(this.fetchSpecificUsers(this.include));
    }

    return $q
      .all(fetchUsers)
      .then(([foundActors, specificActors]) => {
        let actors = foundActors;
        if (specificActors) {
          actors = actors.concat(specificActors);
        }
        // if there is an id to exclude (via the exclude param), we filter it out here
        if (this.exclude) {
          const excludeSet = (
            isArray(this.exclude) ? this.exclude : [this.exclude]
          ).map(toLower);
          return differenceWith(
            actors,
            excludeSet,
            (actor, id) => toLower(actor.id) === id
          );
        } else {
          return actors;
        }
      })
      .then((users) => {
        // we remap id to Id because the backend returns it as "id"
        // and everywhere else in the app we use "Id"
        return users.map(this.multipleStandardsAdapter);
      })
      .then((users) => sortBy(users, 'fullName'))
      .then((users) => sortedUniqBy(users, 'id'))
      .catch(this.onDataLoadError);
  }

  fetchSpecificUsers(idsToFetch: string | string[]) {
    if (!idsToFetch) return [];

    const { $q, userApiService } = this.svcs;

    idsToFetch = Array.isArray(idsToFetch) ? idsToFetch : [idsToFetch];

    const requests = idsToFetch.map((idToFetch) => {
      return this.loadUser
        ? this.loadUser(idToFetch, 'id', { businessId: this.businessId }).then(
            (user) => user
          )
        : userApiService
            .getUserBasicInfoByFieldName(idToFetch)
            .then((user) => user);
    });

    return $q.all(requests);
  }
}

SelectUserController.$inject = [
  '$q',
  'lodashService',
  'userApiService',
  'sessionService'
];
angular
  .module('flowingly.components')
  .controller('selectUserController', SelectUserController);

angular.module('flowingly.components').component('selectUser', {
  bindings: {
    ngModel: '=',
    exclude: '<', // UserId | UserId[] to exclude
    include: '<', // UserId | UserId[] to exclude
    businessId: '<',

    // pass through bindings
    isDisabled: '<',
    noEmpty: '<', // if true, everytime the box is emptied, it returns to the original
    onSelect: '<',
    onChange: '<',
    onClose: '<',
    onOpen: '<',
    onDataLoadError: '<',
    loadUser: '<'
  },
  controller: 'selectUserController',
  template: `
                <kendo-combo-box-facade 
                    ng-if="$ctrl.fetchUsers"
                    ng-model="$ctrl.ngModel"
                    autocomplete=true
                    is-disabled="isDisabled"
                    placeholder="Select User"
                    field-text="_selectText"
                    field-value="_selectValue"
                    clear-button="false"
                    no-empty="$ctrl.noEmpty"
                    on-select="$ctrl.onSelect"
                    on-change="$ctrl.onChange"
                    on-close="$ctrl.onClose"
                    on-open="$ctrl.onOpen"
                    data="$ctrl.fetchUsers">
                </kendo-combo-box-facade>
                `
});
