/**
 * @ngdoc service
 * @name smartSelectService
 * @module flowingly.components
 *
 * @description A service responsible solely for smart select component
 *
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/bb111a8679479a2501a9d0d7b3850e0d12692f8f/src/Flowingly.Shared.Angular/flowingly.components/smartselect/smart.select.service.js?at=master
 */
(function () {
  'use strict';

  angular
    .module('flowingly.components')
    .factory('smartSelectService', smartSelectService);

  smartSelectService.$inject = ['$http', 'APP_CONFIG', 'flowinglyConstants'];

  function smartSelectService($http, APP_CONFIG, flowinglyConstants) {
    var hasMoreResults = false;

    function getUsers(searchTerm) {
      return $http.get(
        APP_CONFIG.apiBaseUrl +
          `users/search?maxNumberOfUsersToShow=${
            flowinglyConstants.maxNumberOfUsersToShow
          }${searchTerm ? '&searchTerm=' + searchTerm : ''}`
      );
    }

    function getGroups() {
      return $http.get(APP_CONFIG.apiBaseUrl + 'teams?includeDetails=false');
    }

    function getAvailableApprovers(filter, searchTerm, noSpinner) {
      return $http.get(
        `${
          APP_CONFIG.apiBaseUrl
        }workflowinstance/${filter}/availableStepApprovers?maxNumberOfUsersToShow=${
          flowinglyConstants.maxNumberOfUsersToShow
        }${searchTerm ? '&searchTerm=' + searchTerm : ''}`,
        { noSpinner: noSpinner || false }
      );
    }

    function getHasMoreResults() {
      return hasMoreResults;
    }

    function setHasMoreResults(val) {
      hasMoreResults = val;
    }
    var service = {
      getUsers: getUsers,
      getGroups: getGroups,
      getAvailableApprovers: getAvailableApprovers,
      getHasMoreResults: getHasMoreResults,
      setHasMoreResults: setHasMoreResults
    };

    return service;
  }
})();
