/**
 * @ngdoc service
 * @name teamApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for team data
 */

import IGroupDetail from '@Shared.Angular/@types/core/contracts/queryModel/group/groupDetail';
import ITeamsResultModel from '@Shared.Angular/@types/core/contracts/queryModel/team/teamsResultModel';
import IUserDetail from '@Shared.Angular/@types/core/contracts/queryModel/user/userDetail';
import Guid from '@Shared.Angular/@types/guid';
import { Services } from '@Shared.Angular/@types/services';
import angular, { IHttpService } from 'angular';

angular.module('flowingly.services').factory('teamApiService', teamApiService);

teamApiService.$inject = ['$http', 'APP_CONFIG', 'guidService'];

function teamApiService(
  $http: IHttpService,
  APP_CONFIG: Services.APP_CONFIG,
  guidService: Services.GuidService
) {
  const service = {
    teams: [] as IGroupDetail[],
    getTeamsWithOptions: getTeamsWithOptions,
    getUsersInTeam: getUsersInTeam,
    saveTeam: saveTeam,
    deleteTeam: deleteTeam
  };
  const teamsApiBaseUrl = APP_CONFIG.apiBaseUrl + 'teams';

  return service;

  function deleteTeam(item, actor) {
    return $http({
      url: APP_CONFIG.apiBaseUrl + 'teams/',
      method: 'DELETE',
      data: {
        deletedTeamId: item.id,
        replacedActorId: actor
      },
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    });
  }

  function saveTeam(team: IGroupDetail) {
    const isNewTeam = !team.id;
    if (isNewTeam) {
      team.id = guidService.empty();
      team.businessId = guidService.empty();
    }

    return $http
      .post<IGroupDetail>(teamsApiBaseUrl, team)
      .then(function (response) {
        const savedTeam = response.data;

        if (isNewTeam) {
          service.teams.push(response.data);
        } else {
          for (const [index, t] of service.teams.entries()) {
            if (t.id === savedTeam.id) {
              service.teams[index] = savedTeam;
              break;
            }
          }
        }

        sortTeamsByName();
        return savedTeam;
      });
  }

  function getTeamsWithOptions(options) {
    return $http
      .post<ITeamsResultModel>(
        teamsApiBaseUrl + '/getTeamsWithOptions',
        options,
        {
          noSpinner: true
        }
      )
      .then(function (response) {
        angular.copy(response.data.teams, service.teams);
        return response.data;
      });
  }

  function getUsersInTeam(teamId: Guid) {
    return $http
      .get<IUserDetail[]>(
        teamsApiBaseUrl + '/getUsersInTeam?teamId=' + teamId,
        {
          noSpinner: true
        }
      )
      .then(function (response) {
        return response.data;
      });
  }

  // PRIVATE METHODS /////////////////////////////////////////////////////////////////
  function sortTeamsByName() {
    service.teams.sort((t1, t2) => {
      const t1NameToCompare = t1.name.trim().toLowerCase();
      const t2NameToCompare = t2.name.trim().toLowerCase();

      if (t1NameToCompare < t2NameToCompare) {
        return -1;
      }

      if (t1NameToCompare > t2NameToCompare) {
        return 1;
      }

      return t1NameToCompare > t2NameToCompare;
    });
  }
}

export type TeamApiServiceType = ReturnType<typeof teamApiService>;
