/**
 * @ngdoc directive
 * @name flowTableViewer
 * @module flowingly.components
 * @description  This comppnent is responsbile for displaying a table in the Runner history view.
 * ### Notes
 * This component shows the data entered by the user for the table, when they completed the step.
 * ### Properties
 * #### Inputs
 * * tableData : the JSON table data to display.
 * * noRowData : boolean tell if has any table data
 * * noRowDataMessage : the message to display when no table data
 * @usage
 * ```
     <flow-table-viewer table-data="item.value"></flow-table-viewer>
 * ```
 * Converted to ts on 17/01/2020
 * See https://bitbucket.org/flowingly-team/flowingly-source-code/src/8db4d4f422bc44beda7c174555866ff0de7c3f63/src/Flowingly.Shared.Angular/flowingly.components/tableviewer/table.viewer.component.js?at=master
 */
(function () {
  'use strict';

  angular.module('flowingly.components').component('flowTableViewer', {
    bindings: {
      //this input contains the data saved when the user completed the form (which the table was part of)
      tableData: '<',
      tableSchema: '<',
      noRowData: '<',
      step: '<',
      noRowDataMessage: '@?'
    },
    templateUrl: 'table.viewer.component.tmpl.html',
    controller: [
      '$sce',
      'APP_CONFIG',
      'lodashService',
      'sessionService',
      'flowinglyFormulaService',
      'flowinglyConstants',
      'tokenService',
      function (
        $sce,
        APP_CONFIG,
        lodashService,
        sessionService,
        flowinglyFormulaService,
        flowinglyConstants,
        tokenService
      ) {
        const $ctrl = this;
        $ctrl.$sce = $sce;
        $ctrl.table = {};
        let tableSchema;

        this.$onInit = function () {
          if (!$ctrl.noRowData) {
            if ($ctrl.tableSchema) {
              tableSchema = JSON.parse($ctrl.tableSchema);
            }
            $ctrl.userId = sessionService.getUser().id;
            $ctrl.businessId = tokenService.getTenant().id;
            $ctrl.table = generateTable($ctrl.tableData);
          }

          $ctrl.formatFormula = (cells, cell) => {
            return flowinglyFormulaService.formatCell(
              $ctrl.step.Schema,
              cells,
              cell
            );
          };

          $ctrl.isCurrencyCell = (cells, cell) => {
            return flowinglyFormulaService.isCurrencyCell(
              $ctrl.step.Schema,
              cells,
              cell
            );
          };

          $ctrl.isNumberCell = (cells, cell) => {
            return flowinglyFormulaService.isNumberCell(
              $ctrl.step.Schema,
              cells,
              cell
            );
          };
        };

        /// PRIVATE METHODS //////////////////////////////////////////////////////////

        function generateTable(tableData) {
          const table = JSON.parse(tableData);
          formatTypes(table);
          formatTotals(table);
          formatImageUrls(table);
          return table;
        }

        function formatImageUrls(table) {
          lodashService.forEach(table.rows, function (row) {
            lodashService.forEach(row.cells, function (cell) {
              if (cell.type === flowinglyConstants.tableCellType.FILE) {
                //if no file was uploaded cell.value will be undefined
                const filepath = generateLink(cell.value);
                const downloadLink = generateDownloadLink(cell.value);
                cell.value =
                  filepath !== undefined
                    ? {
                        fileId: cell.value,
                        filepath: filepath,
                        downloadLink: downloadLink
                      }
                    : undefined;
              }
            });
          });
        }

        function formatTypes(table) {
          lodashService.forEach(table.rows, function (row) {
            lodashService.forEach(row.cells, function (cell) {
              if (
                cell.type === flowinglyConstants.tableCellType.LOOKUP ||
                cell.type === flowinglyConstants.tableCellType.FORMULA
              ) {
                const cellSchema = tableSchema.find((s) => s.id === cell.id);
                if (cellSchema && cellSchema.lookupConfig) {
                  cell.lookupConfig = cellSchema.lookupConfig;
                }
                if (cellSchema && cellSchema.formulaConfig) {
                  cell.formulaConfig = cellSchema.formulaConfig;
                }
              }
            });
          });
        }

        function formatTotals(table) {
          //if there are any number / currency columns, we create an array of totals
          let foundNumberOrCurrency = false;
          $ctrl.sumCells = [];
          lodashService.forEach(table.rows, function (row) {
            lodashService.forEach(row.cells, function (cell) {
              const existing = lodashService.find(
                $ctrl.sumCells,
                function (sumCell) {
                  return sumCell.id === cell.id;
                }
              );

              if (
                flowinglyFormulaService.hasSumCell(
                  $ctrl.step.Schema,
                  row.cells,
                  cell
                )
              ) {
                foundNumberOrCurrency = true;
                if (existing) {
                  existing.sum += calculateCellSum(cell);
                } else {
                  const sumInitValue = calculateCellSum(cell);
                  $ctrl.sumCells.push(createCellSum(cell, sumInitValue));
                }
              } else {
                if (!existing) {
                  $ctrl.sumCells.push(createCellSum(cell));
                }
              }
            });
          });
          //if did not find any number or currency columns clear out the data so total row not displayed.
          if (!foundNumberOrCurrency) {
            $ctrl.sumCells = [];
          }
        }

        function createCellSum(cell, sum = null) {
          const sumCell = {
            id: cell.id,
            type: cell.type
          };
          if (cell.lookupConfig) {
            sumCell['lookupConfig'] = cell.lookupConfig;
          }
          if (cell.formulaConfig) {
            sumCell['formulaConfig'] = cell.formulaConfig;
          }
          if (sum !== null) {
            sumCell['sum'] = sum;
          }
          return sumCell;
        }

        function calculateCellSum(cell) {
          let sum = 0;
          const cellValue = cell.value === undefined ? 0.0 : cell.value;

          if (flowinglyFormulaService.hasLookupSumCell(cell)) {
            if (typeof cellValue === 'string' && cellValue.indexOf(',') >= 0) {
              const valueArray = cellValue.split(',');

              for (const v of valueArray) {
                if (!isNaN(parseFloat(v))) {
                  sum += parseFloat(v);
                }
              }
            } else {
              if (!isNaN(parseFloat(cellValue))) {
                sum += parseFloat(cellValue);
              }
            }
          } else {
            sum += Number(cellValue);
          }

          return sum;
        }

        function generateLink(fileId) {
          return fileId !== undefined
            ? APP_CONFIG.apiBaseUrl +
                'files/' +
                $ctrl.businessId +
                '/' +
                $ctrl.userId +
                '/' +
                fileId
            : undefined;
        }
        function generateDownloadLink(fileId) {
          return fileId !== undefined
            ? `${APP_CONFIG.apiBaseUrl}files/download/${fileId}`
            : undefined;
        }
      }
    ]
  });
})();
