/**
 * @ngdoc type
 * @module flowingly.bpmn.modeler'
 * @name c
 *
 * @description Constants for use within the modeler.bpmn module.
 */
'use strict';
import angular from 'angular';

///
/// This module contains BPMN constants
///

/// Theme colours for flowingly to use in BPMN models. (These could be injected if BpmnService made a provider)
const theme = {
  FlowinglyStrokeWidth: 5,
  FlowinglyPaletteStrokeWidth: 3,
  FlowinglyWhite: '#FFF',
  FlowinglyGreen: '#94D895',
  FlowinglyBlue: '#35384C',
  FlowinglyOrange: '#f57c00',
  FlowinglyRed: '#F58779',
  FlowinglyGrey: '#B5B5B5',
  FlowinglyLightGrey: '#EFEFEF',
  FlowinglyMediumGrey: '#999',
  FlowinglyDarkGrey: '#555',
  FlowinglyPaletteGrey: '#727272',
  FlowinglyPaletteText: '#35384D',
  FlowinglyBlack: '#000',
  FlowinglyBlue2: '#408EE0',
  FlowinglyLightGrey2: '#D1D1D1',
  FlowinglyHighlightedNode: '#D0E6FE',

  Error: {
    Border: {
      Color: '#F66', // used in border coloring
      StrokeWidth: 2
    }
  }
};

const CONSTANTS = {
  Theme: theme,
  //Options
  MakeNodesEditable: false,

  RoutingMode: 3, //1 = Normal, 2 = Orthogonal, 3 = AvoidNodes
  LinkCornerCurve: 10,

  DiagramScale: 0.8,
  DiagramMinScale: 0.0001,
  DiagramMaxScale: 100,

  EventNodeSize: 42,
  EventNodeInnerSize: 36, //(EventNodeSize -6);

  //Activity Node
  ActivityNodeWidth: 500,
  ActivityNodeHeight: 500,
  ActivityNodeStrokeWidth: 1.5,
  ActivityNodePaletteStrokeWidth: theme.FlowinglyPaletteStrokeWidth,
  ActivityMarkerStrokeWidth: 1.5,
  ActivityNodeBorder: '#777',
  ActivityNodeFill: theme.FlowinglyWhite,
  TerminateInnerCircle: theme.FlowinglyOrange,
  ActivityHeaderFillColor: theme.FlowinglyLightGrey,
  ActivitySquareFillColor: theme.FlowinglyLightGrey2,
  ActivitySquareHighlightColor: theme.FlowinglyHighlightedNode,
  ACTIVITY: 'activity',

  //Gateway
  GATEWAY: 'gateway',
  GatewayNodeSize: 80,
  GatewayNodeFill: theme.FlowinglyWhite,
  GatewayNodeStroke: theme.FlowinglyDarkGrey,
  GatewayNodeStrokeWidth: theme.FlowinglyStrokeWidth,
  GatewayNodePaletteStrokeWidth: theme.FlowinglyPaletteStrokeWidth,

  //Events
  EventNodeStrokeWidth: theme.FlowinglyStrokeWidth,
  EventNodePaletteStrokeWidth: theme.FlowinglyPaletteStrokeWidth,
  EventNodeFill: theme.FlowinglyWhite,
  EventNodePaletteFill: theme.FlowinglyLightGrey,
  EventStartBorder: theme.FlowinglyGreen,
  EventEndBorder: theme.FlowinglyRed,
  LightGreenBrush: { 0: theme.FlowinglyGreen, 1: theme.FlowinglyGreen },
  LightOrangeBrush: { 0: theme.FlowinglyOrange, 1: theme.FlowinglyOrange },

  //Palette scale
  GatewayPaletteScale: 3.2,
  ActivityPaletteScale: 2,
  EventPaletteScale: 2,
  NoScale: 1,
  PaletteGap: 40,

  EmailIconColour: theme.FlowinglyDarkGrey,
  PublicFormIconColour: theme.FlowinglyDarkGrey,
  ApprovalIconColour: theme.FlowinglyBlue2,
  StepRuleIconColour: theme.FlowinglyBlue2,
  IntegrationIconColour: theme.FlowinglyBlue2,

  // LINKS
  LinkColour: '#777',
  BackLinkColour: theme.FlowinglyMediumGrey,
  LinkWidth: 2,

  //TEXT
  TextColour: theme.FlowinglyPaletteText,
  PaletteTextColour: theme.FlowinglyPaletteText,

  palette: {
    innerWidth: 52,
    outerWidth: 61,
    icon: {
      innerWidth: 39,
      outerWidth: 39,
      innerHeight: 39,
      outerHeight: 39
    },
    iconIE: {
      event: {
        innerWidth: 50,
        innerHeight: 50,
        scale: 0.8
      },
      activity: {
        innerWidth: 50,
        innerHeight: 50,
        scale: 0.8
      },
      email: {
        innerWidth: 100,
        innerHeight: 100,
        scale: 0.4
      },
      gateway: {
        innerWidth: 70,
        innerHeight: 70,
        scale: 0.6
      },
      pool: {
        innerWidth: 50,
        innerHeight: 50,
        scale: 0.8
      },
      component: {
        innerWidth: 50,
        innerHeight: 50,
        scale: 0.8
      }
    },
    textBlock: {
      innerWidth: 100
    }
  },
  diagram: {
    emailIcon: {
      outerWidth: 100,
      outerHeight: 100
    },
    gatewayIcon: {
      outerWidth: 70,
      outerHeight: 70
    },
    icon: {
      innerWidth: 70,
      outerWidth: 50,
      innerHeight: 70,
      outerHeight: 50
    },
    shadow: {
      color: '#A2A2A2',
      blur: 7,
      offset: [2, 3, 0, 0]
    },
    webhooksSvgIcon: {
      width: 28,
      height: 28,
      scale: 1
    },
    webhooksSvgIconIE: {
      width: 45,
      height: 45,
      scale: 0.6
    }
  }
};

export default CONSTANTS;

///
/// These values are shared across one or more services
/// @TODO convert all of these into  more of a namespaced thingy majig
angular.module('flowingly.bpmn.modeler').constant('BPMN_CONSTANTS', CONSTANTS);
